import React, { Component } from 'react';
import './Home.css';

import Header from '../../_components/Header';
import Footer from '../../_components/Footer';
import OnlineNews from '../../_components/OnlineNews';
import CetakNews from '../../_components/CetakNews';
import GrafikNews from '../../_components/GrafikNews';
import Graphtopissue from '../Quantitative/Graphtopissue';
import Graphtonemedia from '../Quantitative/Graphtonemedia';
import Graphtonenarasumber from '../Quantitative/Graphtonenarasumber';
import RightSidebar from '../../_components/RightSidebar';
import TVNews from '../../_components/TVNews';
import { API, CLIENT, ASSETS_URL } from '../../_helpers/Constants';
import { DateHelper } from '../../_helpers/DateHelper';
import axios from 'axios';

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateFrom: '2019-01-01',
            dateTo: DateHelper(),
            client: JSON.parse(localStorage.getItem('_profile')),
            dataNews: [],
            dataOnline: [],
            dataTv: [],
            dataCetak: [],
        };
    }

    componentDidMount() {
        // Set interval to reload page every 30 minutes
        this.reloadInterval = setInterval(() => {
            window.location.reload();
        }, 30 * 60 * 1000); // 30 minutes in milliseconds
        this.getDataNews(1);
        this.getDataNews(2);
        this.getDataNews(3);
    }

    getDataNews(type) {
        var self = this;
        var urls = API + "news/getall?username=" + localStorage.getItem('_username')
            + "&token=" + localStorage.getItem('_token')
            + "&subdomain_id=" + CLIENT
            + "&limit=10"
            + "&headline=1"
            + "&date_from=2017-01-01&date_to=2024-12-31";

        urls = urls + "&type=" + type

        axios({
            method: 'get',
            url: urls,
        }).then(function (response) {
            if (response.data.status === 200) {
                let dataArr = response.data.data;
                response.data.data.map((item, i) => {
                    item.berita_foto = ASSETS_URL + "img/photo/" + item.berita_foto;
                });

                if (type === 1) {
                    self.setState({ dataOnline: dataArr });
                } else if (type === 2) {
                    self.setState({ dataTv: dataArr });
                    console.log("TVnya", urls);
                } else if (type === 3) {
                    self.setState({ dataCetak: dataArr });
                }


            }
            self.setState({ isLoading: false });
        }).catch(function (error) {
            self.setState({ isLoading: false });
        });
    }

    componentWillUnmount() {
        // Clear the interval on component unmount
        clearInterval(this.reloadInterval);
    }

    render() {
        return (
            <div>
                <Header />

                <div className="hero-area">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="container">
                                    <br></br>
                                    <hr></hr>
                                    <div className='row'>
                                        <div className="col-12 col-md-4 col-lg-4">

                                            <div className="col-12 col-lg-12">
                                                <Graphtopissue title="Grafik Top Isu" height={250} dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} />
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-4 col-lg-4">
                                            <div className='col-12 col-md-12 col-lg-12'>
                                                <Graphtonemedia title="Grafik Tone Media" widt="300" height="250" dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} ref={this.forceUpdateToneMedia} />
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-4 col-lg-4">
                                            <div className='col-12 col-md-12 col-lg-12'>
                                                <Graphtonenarasumber title="Grafik Tone Narasumber" width="300" height="250" dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} ref={this.forceUpdateToneNarasumber} />
                                            </div>
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row align-items-center">
                            <div className="col-lg-9">
                                <OnlineNews data={this.state.dataOnline} />
                            </div>
                            <div className="col-lg-3 align-self-start">
                                <RightSidebar data={this.state.dataOnline} />
                            </div>
                        </div>

                        <div className="row align-items-center">
                            <div className="col-12 col-lg-12">
                                <CetakNews data={this.state.dataCetak} />
                                <TVNews data={this.state.dataTv} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-6 bdz-medsos-item">
                            {/* Content goes here */}
                        </div>
                        <div className="col-6 bdz-medsos-item">
                            {/* Content goes here */}
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}
