import React from 'react'; // Pastikan React diimpor karena Anda menggunakan JSX
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Impor FontAwesomeIcon
import { faBars, faPencilAlt } from '@fortawesome/free-solid-svg-icons'; // Impor ikon yang diperlukan

export const newsItems = [
  {
    title: 'Judul Berita 1',
    description: 'Deskripsi Berita Deskripsi Berita Deskripsi Berita 1',
    imageUrl: 'https://via.placeholder.com/300x200',
    likes: 12,
    dislikes: 5,
  },
  {
    title: 'Judul Berita 2',
    description: 'Deskripsi Berita Deskripsi Berita Deskripsi Berita 2',
    imageUrl: 'https://via.placeholder.com/300x200',
    likes: 12,
    dislikes: 5,
  },
  {
    title: 'Judul Berita 3',
    description: 'Deskripsi Berita Deskripsi Berita Deskripsi Berita 3',
    imageUrl: 'https://via.placeholder.com/300x200',
    likes: 12,
    dislikes: 5,
  },
  {
    title: 'Judul Berita 4',
    description: 'Deskripsi Berita Deskripsi Berita Deskripsi Berita 4',
    imageUrl: 'https://via.placeholder.com/300x200',
    likes: 12,
    dislikes: 5,
  },
];

export const newsItems2 = [
  {
    title: 'Judul Berita 1',
    description: 'Deskripsi Berita Deskripsi Berita Deskripsi Berita 1',
    imageUrl: 'https://via.placeholder.com/300x200',
    likes: 12,
    dislikes: 5,
  },
  {
    title: 'Judul Berita 2',
    description: 'Deskripsi Berita Deskripsi Berita Deskripsi Berita 2',
    imageUrl: 'https://via.placeholder.com/300x200',
    likes: 12,
    dislikes: 5,
  },
  {
    title: 'Judul Berita 3',
    description: 'Deskripsi Berita Deskripsi Berita Deskripsi Berita 3',
    imageUrl: 'https://via.placeholder.com/300x200',
    likes: 12,
    dislikes: 5,
  },
];

export const headerLink = [
  {
    title: 'Olimpiade',
    url: '#',
  },
  {
    title: (
      <>
        | <FontAwesomeIcon icon={faBars} className="news-icon" />Subang / 20 C{' '}
        <FontAwesomeIcon icon={faPencilAlt} className="news-icon" /> |
      </>
    ),
    url: '#',
  },
  {
    title: 'Olahraga',
    url: '#',
  },
  {
    title: 'Ekonomi',
    url: '#',
  },
  {
    title: 'Sehat dan Bugar',
    url: '#',
  },
  {
    title: 'Travel',
    url: '#',
  },
  {
    title: 'Otomotif',
    url: '#',
  },
  {
    title: 'Video',
    url: '#',
  },
  {
    title: 'Game Kasual',
    url: '#',
  },
];


export const headerLinks = [
  { title: 'Ekonomi >', url: '#' },
  { title: 'Pasar', url: '#' },
  { title: 'Daftar Pantau Saya', url: '#' },
  { title: 'Keuangan Pribadi', url: '#' },
  { title: 'Pengonversi Mata Uang', url: '#' },
];
