import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import { Doughnut } from 'react-chartjs-2';
import { Link } from 'react-router-dom';

const RightSidebar = (news) => {
  const doughnutData = {
    labels: ['Positif'],
    datasets: [
      {
        label: 'Tone Berita',
        data: [100],
        backgroundColor: ['#28a745'],
        hoverBackgroundColor: ['#218838'],
        borderWidth: 2,
      },
    ],
  };

  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: 'Grafik Tone Berita',
        font: {
          size: 18,
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          boxWidth: 10,
        },
      },
    },
    cutout: '70%', // Creates a donut-shaped chart
  };

  const cardStyle = {
    width: '100%',
    height: '100%',
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
  };

  const doughnutContainerStyle = {
    height: '250px',
  };

  const listItemStyle = {
    display: 'flex',
    alignItems: 'center',
  };

  const listItemNumberStyle = {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: '#007bff',
    marginRight: '10px',
    width: '20px',
    textAlign: 'center',
  };

  const listItemTextStyle = {
    fontSize: '0.6rem',
    lineHeight: '1.2',
    flexGrow: 1,
    textAlign: 'left',
  };

  return (
    <Card style={cardStyle}>
      <Card.Body style={doughnutContainerStyle}>
        <Doughnut data={doughnutData} options={doughnutOptions} />
      </Card.Body>
      <Card.Body style={{ flexGrow: 1 }}>
        <h5 style={{ fontWeight: 'bold' }}>Terpopuler</h5>
        <ListGroup variant="flush">
          {news.data.map((item, index) => (
            index < 3 && (
            <ListGroup.Item key={index}>
              <Link to={`/news/${item.berita_id}`}>
                <div style={listItemStyle}>
                  <span style={listItemNumberStyle}>{index + 1}</span>
                  <span style={listItemTextStyle}>{item.berita_isu}</span>
                </div>
              </Link>
            </ListGroup.Item>
            )
          ))}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

export default RightSidebar;
