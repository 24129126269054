import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image, Carousel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown, faHeart, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import '../_assets/css/OnlineNews.css';
import { headerLink } from '../_assets/data/newsData';
import ShortText from '../_helpers/ShortText';

function getIconStyle(isClicked) {
  return {
    color: isClicked ? 'black' : 'transparent',
    stroke: 'black',
    strokeWidth: '20px',
    cursor: 'pointer',
  };
}

function NewsItem({ item, index, liked, disliked, onLikeToggle, onDislikeToggle }) {
  return (
    <Row className="news-item">
      <Col md={8}>
        <Row className="align-items-center">
          <Col>
            <Link to={`/news/${item.berita_id}`}>
              <FontAwesomeIcon icon={faHeart} className="news-icon" />
              <h6 className="news-title">{ShortText(item.berita_isu, 30)}</h6>
            </Link>
          </Col>
        </Row>
        <Link to={`/news/${item.berita_id}`}>
          <p className="news-item-content"><b>{ShortText(item.berita_judul, 60)}</b></p>
        </Link>
        <FontAwesomeIcon
          icon={faThumbsUp}
          style={getIconStyle(liked[index])}
          onClick={() => onLikeToggle(index)}
          className="news-icon"
        /> {item.likes}
        <FontAwesomeIcon
          icon={faThumbsDown}
          style={{ ...getIconStyle(disliked[index]), marginLeft: '16px' }}
          onClick={() => onDislikeToggle(index)}
          className="news-icon-dislike"
        /> {item.dislikes}
      </Col>
      <Col md={4} className="image-container">
        <Link to={`/news/${item.berita_id}`}>
          <Image src={item.berita_foto} fluid className="full-image" />
        </Link>
      </Col>
    </Row>
  );
}

function OnlineNews({ data }) {
  const [liked, setLiked] = useState(Array(data.length).fill(false));
  const [disliked, setDisliked] = useState(Array(data.length).fill(false));

  const handleLikeToggle = (index) => {
    const newLiked = [...liked];
    newLiked[index] = !newLiked[index];
    setLiked(newLiked);

    if (newLiked[index]) {
      const newDisliked = [...disliked];
      newDisliked[index] = false;
      setDisliked(newDisliked);
    }
  };

  const handleDislikeToggle = (index) => {
    const newDisliked = [...disliked];
    newDisliked[index] = !newDisliked[index];
    setDisliked(newDisliked);

    if (newDisliked[index]) {
      const newLiked = [...liked];
      newLiked[index] = false;
      setLiked(newLiked);
    }
  };

  return (
    <Container className="margin-bottom-50">
      <h1 className="online-header"><b>Online</b></h1>
      <Row style={{ backgroundColor: '#F4F3F5' }}>
        {/* Kolom Utama Besar (2/3) */}
        <Col md={8}>
          <Carousel
            className="custom-carousel"
            prevIcon={
              <span className="carousel-control-prev">
                <FontAwesomeIcon icon={faChevronLeft} className="icon" />
              </span>
            }
            nextIcon={
              <span className="carousel-control-next">
                <FontAwesomeIcon icon={faChevronRight} className="icon" />
              </span>
            }
            prevLabel=""
            nextLabel=""
          >
            {data.map((item, index) => (
              <Carousel.Item key={index}>
                <img
                  className="d-block w-100"
                  src={item.berita_foto}
                  alt={item.berita_isu}
                />
                <Carousel.Caption>
                  <h3>{item.berita_isu}</h3>
                  <p>{item.berita_judul}</p>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>

          <Row className="flex-container my-4">
            {data.slice(0, 4).map((item, index) => (
              <div className="flex-item" key={index}>
                <NewsItem
                  item={item}
                  index={index}
                  liked={liked}
                  disliked={disliked}
                  onLikeToggle={handleLikeToggle}
                  onDislikeToggle={handleDislikeToggle}
                />
              </div>
            ))}
          </Row>
        </Col>
        {/* Kolom Kedua (1/3) */}
        <Col md={4}>
          <Row style={{ marginBottom: '16px', padding: '10px' }}>
            <Image src={data && data[0] && data[0].berita_foto ? data[0].berita_foto : ''} fluid style={{ height: '207px' }} />
          </Row>
          <Row style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
          }}>
            {data.slice(4, 7).map((item, index) => (
              <Col key={index} md={12} style={{
                flex: '1 1 auto',
                marginBottom: '12px',
                display: 'flex',
                flexDirection: 'column',
              }}>
                <NewsItem
                  item={item}
                  index={index}
                  liked={liked}
                  disliked={disliked}
                  onLikeToggle={handleLikeToggle}
                  onDislikeToggle={handleDislikeToggle}
                />
              </Col>
            ))}
          </Row>

        </Col>
      </Row>
    </Container>
  );
}

export default OnlineNews;
