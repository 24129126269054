import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown, faHeart } from '@fortawesome/free-solid-svg-icons';
import ShortText from '../_helpers/ShortText';

const styles = {
  container: {
    marginBottom: '50px',
  },
  onlineHeader: {
    color: 'white',
    backgroundColor: 'black',
    display: 'inline-block',
  },
  headerLinkContainer: {
    marginLeft: '2px',
    whiteSpace: 'nowrap',
    color: '#646462',
  },
  headerLinkCol: (index) => ({
    paddingLeft: index === 0 ? '0px' : '15px',
  }),
  sectionTitle: {
    fontSize: '12px',
  },
  newsItem: {
    padding: '0',
    height: '100%',
  },
  imageContainer: {
    backgroundColor: 'white',
    padding: '0',
    height: '82%',
    marginTop: '10px',
  },
  imageWrapper: {
    overflow: 'hidden',
    height: '70%',
  },
  fullImage: {
    height: '100%',
    objectFit: 'cover',
  },
  newsItemContent: {
    padding: '10px',
  },
  sectionContent: {
    padding: '0',
  },
  newsTitle: {
    fontSize: '10px',
  },
  rightColumn: {
    paddingRight: '30px',
  },
};

function getIconStyle(isClicked) {
  return {
    color: isClicked ? 'black' : 'transparent',
    stroke: 'black',
    strokeWidth: '20px',
    cursor: 'pointer',
  };
}

function NewsItemWithImageOnTop({ item, index, liked, disliked, onLikeToggle, onDislikeToggle }) {
  return (

    <Row style={styles.newsItem}>
      <Col style={styles.imageContainer}>
        <div style={styles.imageWrapper}>
          <Image
            src={item.berita_foto}
            fluid
            className="full-image"
            style={styles.fullImage}
          />
        </div>
        <div style={styles.newsItemContent}>
          <Row className="align-items-center">
            <Col>
              <Link to={`/news/${item.berita_id}`}>
                <FontAwesomeIcon icon={faHeart} className="news-icon" />
                <h6 className="news-title">{ShortText(item.berita_isu)}</h6>
              </Link>
            </Col>
          </Row>
          <Link to={`/news/${item.berita_id}`}>
            <p className="news-item-content"><b>{ShortText(item.berita_judul)}</b></p>
          </Link>
          <FontAwesomeIcon
            icon={faThumbsUp}
            style={getIconStyle(liked[index])}
            onClick={() => onLikeToggle(index)}
            className="news-icon"
          /> {item.likes}
          <FontAwesomeIcon
            icon={faThumbsDown}
            style={getIconStyle(disliked[index])}
            onClick={() => onDislikeToggle(index)}
            className="news-icon-dislike"
          /> {item.dislikes}
        </div>
      </Col>
    </Row>

  );
}

function NewsItem({ item, index, liked, disliked, onLikeToggle, onDislikeToggle }) {
  return (
    <Link to={`/news/${item.berita_id}`}>
      <Row style={{ backgroundColor: 'white', padding: '7px', margin: '10px' }}>
        <Col md={8} style={{ backgroundColor: 'white' }}>
          <Row className="align-items-center">
            <Col>
              <Link to={`/news/${item.berita_id}`}>
                <FontAwesomeIcon icon={faHeart} className="news-icon" />
                <h6 className="news-title" style={styles.newsTitle}>{ShortText(item.berita_isu)}</h6>
              </Link>
            </Col>
          </Row>
          <Link to={`/news/${item.berita_id}`}>
            <p className="news-item-content"><b>{ShortText(item.berita_judul)}</b></p>
          </Link>
          <FontAwesomeIcon
            icon={faThumbsUp}
            style={getIconStyle(liked[index])}
            onClick={() => onLikeToggle(index)}
            className="news-icon"
          /> {item.likes}
          <FontAwesomeIcon
            icon={faThumbsDown}
            style={getIconStyle(disliked[index])}
            onClick={() => onDislikeToggle(index)}
            className="news-icon-dislike"
          /> {item.dislikes}
        </Col>
        <Col md={4} className="image-container">
          <Link to={`/news/${item.berita_id}`}>
            <Image src={item.berita_foto} fluid className="full-image" />
          </Link>
        </Col>
      </Row>
    </Link>
  );
}

function CetakNews(news) {
  const [liked, setLiked] = useState(Array(4).fill(false));
  const [disliked, setDisliked] = useState(Array(4).fill(false));

  const newsItems = [
    {
      title: 'Judul Berita 1',
      description: 'Deskripsi Berita Deskripsi Berita',
      imageUrl: 'https://via.placeholder.com/300x200',
      likes: 12,
      dislikes: 5,
    },
  ];

  const handleLikeToggle = (index) => {
    const newLiked = [...liked];
    newLiked[index] = !newLiked[index];
    setLiked(newLiked);

    if (newLiked[index]) {
      const newDisliked = [...disliked];
      newDisliked[index] = false;
      setDisliked(newDisliked);
    }
  };

  const handleDislikeToggle = (index) => {
    const newDisliked = [...disliked];
    newDisliked[index] = !newDisliked[index];
    setDisliked(newDisliked);

    if (newDisliked[index]) {
      const newLiked = [...liked];
      newLiked[index] = false;
      setLiked(newLiked);
    }
  };

  return (
    <Container style={styles.container}>
      <h1 className="online-header"><b>Cetak</b></h1>
      <Row style={{ backgroundColor: '#F4F3F5', padding: '25px' }}>
        <Row>
          {/* Left Side */}
          <Col md={6}>
            <Row className="section-content">
              <Col md={6}>
                {news.data.map((item, index) => (
                  index < 1 && (
                    <NewsItemWithImageOnTop
                      key={index}
                      item={item}
                      index={index}
                      liked={liked}
                      disliked={disliked}
                      onLikeToggle={handleLikeToggle}
                      onDislikeToggle={handleDislikeToggle}
                    />
                  )
                ))}
              </Col>
              <Col md={6} style={styles.sectionContent}>
                {news.data.map((item, index) => (
                  index < 3 && (
                    <NewsItem
                      key={index}
                      item={item}
                      index={index}
                      liked={liked}
                      disliked={disliked}
                      onLikeToggle={handleLikeToggle}
                      onDislikeToggle={handleDislikeToggle}
                    />
                  )
                ))}
              </Col>
            </Row>
          </Col>

          {/* Right Side */}
          <Col md={6}>
            <Row className="section-content">
              <Col md={6} style={styles.sectionContent}>
                {news.data.map((item, index) => (
                  index < 3 && (
                    <NewsItem
                      key={index}
                      item={item}
                      index={index}
                      liked={liked}
                      disliked={disliked}
                      onLikeToggle={handleLikeToggle}
                      onDislikeToggle={handleDislikeToggle}
                    />
                  )
                ))}
              </Col>
              <Col md={6}>
                {news.data.map((item, index) => (
                  index < 1 && (
                    <NewsItemWithImageOnTop
                      key={index}
                      item={item}
                      index={index}
                      liked={liked}
                      disliked={disliked}
                      onLikeToggle={handleLikeToggle}
                      onDislikeToggle={handleDislikeToggle}
                    />
                  )
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
    </Container>
  );
}

export default CetakNews;
