import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './Home/Home';
import HomeApp from './Home/App';
import Login from './Login/Login';
import Logout from './Login/Logout';
import News from './News/News';
import Index from './Index/Index';
import Report from './Report/Report';
import Qualitative from './Qualitative/Qualitative';
import Quantitative from './Quantitative/Quantitative';
import Search from './Search/Search';
import PrintReport from './Print/Report';
import Original from './Print/Original';
import Medsos from './medsos/Medsos';



function App() {
  return (
    <Router>
      <Switch>
        <Route exact path='/logout' component={Logout} />
        <Route exact path='/login' component={Login} />
        <Route path='/home' component={Home} />
        <Route path='/app' component={HomeApp} />
        {/* <Route path='/news' component={ News } /> */}
        <Route path='/news/:id' component={News} ></Route>
        <Route path='/index' component={Index} />
        <Route path='/report' component={Report} />
        <Route path='/quantitative' component={Quantitative} />
        <Route path='/qualitative/:type' component={Qualitative} />
        <Route path='/search/:keyword' component={Search} />
        <Route path='/printreport' component={PrintReport} />
        <Route path='/print/original/:id' component={Original} />
        <Route path='/medsos/:type' component={Medsos} />

        <Route path='/' component={Login} />
      </Switch>
    </Router>
  );
}

export default App;
